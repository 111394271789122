import React from "react";
import TeamGrid from "../components/TeamGrid";

export default function AboutPage() {
  return (
    <section>
      <header className="pt-10 text-center col-12 col-sm-9 col-lg-7 col-xl-6 mx-auto">
        <h1 className="display-3 fw-bold mb-3">Apropos de nous</h1>
        <p className="text-muted lead mb-0">
          Lancé en 2017, Invoice92 est une propriété de FATIHOUNE SARL. c'est un
          logiciel de facturation pour les petites et moyennes entreprises qui a
          pour mission de suivre les dépenses, personnalisez les factures,
          générez des rapports et bien plus encore d'un seul endroit.
        </p>
      </header>
      <div class="container py-7">
        <div class="row g-3">
          <div class="col-12 col-md-6 d-none d-md-block">
            <div class="row g-3">
              <div class="col-12 col-md-6">
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm"
                    src={`${process.env.PUBLIC_URL}/assets/images/about-1.jpeg`}
                    alt=""
                  />
                </picture>
              </div>
              <div class="col-12 col-md-6">
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm mb-3"
                    src={`${process.env.PUBLIC_URL}/assets/images/about-2.jpeg`}
                    alt=""
                  />
                </picture>
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm"
                    src={`${process.env.PUBLIC_URL}/assets/images/about-3.jpeg`}
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="row g-3">
              <div class="col-12 col-md-6 d-none d-md-block">
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm"
                    src={`${process.env.PUBLIC_URL}/assets/images/about-4.jpeg`}
                    alt=""
                  />
                </picture>
              </div>
              <div class="col-12 col-md-6">
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm mb-3"
                    src={`${process.env.PUBLIC_URL}/assets/images/about-5.jpeg`}
                    alt="{{ config.defaultImgAlt }}"
                  />
                </picture>
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm"
                    src={`${process.env.PUBLIC_URL}/assets/images/about-6.jpeg`}
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-8 col-lg-6 mx-auto text-center py-4 border-bottom mb-5">
          <div className="my-5 d-none d-md-flex align-items-start justify-content-between">
            <div>
              <span className="display-3 fw-bold text-primary d-block">12</span>
              <span className="d-block fs-9 fw-bolder tracking-wide text-uppercase text-muted">
                Pays
              </span>
            </div>
            <div>
              <span className="display-3 fw-bold text-primary d-block">
                75K
              </span>
              <span className="d-block fs-9 fw-bolder tracking-wide text-uppercase text-muted">
                clients
              </span>
            </div>
            <div>
              <span className="display-3 fw-bold text-primary d-block">
                160
              </span>
              <span className="d-block fs-9 fw-bolder tracking-wide text-uppercase text-muted">
                Employés
              </span>
            </div>
          </div>
        </div>

        <div class="py-6 row gx-8 align-items-center">
          <div class="col-12 col-lg-6">
            <p class="mb-3 small fw-bolder tracking-wider text-uppercase text-primary">
              Comment ça a commencé
            </p>
            <h2 class="display-5 fw-bold mb-6">Notre histoire</h2>
            <p>
              Depuis notre création en 20XX, nous avons tracé notre chemin dans
              le monde en constante évolution de la technologie. Notre histoire
              est celle d'une équipe passionnée, de défis relevés et
              d'innovations qui ont marqué l'ère numérique.
            </p>
            <p>
              Tout a commencé avec une vision simple : aider les entreprises à
              exploiter tout le potentiel de la technologie pour atteindre leurs
              objectifs. Notre fondateur, [Nom du Fondateur], a lancé
              l'entreprise avec une passion pour l'informatique et une
              détermination à fournir des solutions exceptionnelles.
            </p>
          </div>
          <div class="col-12 col-lg-6">
            <picture>
              <img
                class="img-fluid rounded shadow-sm"
                src={`${process.env.PUBLIC_URL}/assets/images/about-7.jpeg`}
                alt=""
              />
            </picture>
          </div>
        </div>

        <div class="py-6 row gx-8 align-items-center">
          <div class="col-12 col-lg-6">
            <picture>
              <img
                class="img-fluid rounded shadow-sm"
                src={`${process.env.PUBLIC_URL}/assets/images/about-7.jpeg`}
                alt=""
              />
            </picture>
          </div>
          <div class="col-12 col-lg-6">
            <h2 class="display-5 fw-bold mb-6">Nos valeurs</h2>
            <p>
              À FATIHOUNE SARL, nos valeurs sont ancrées au cœur de tout ce que
              nous faisons. Elles guident notre équipe chaque jour, influençant
              notre approche et notre engagement envers nos clients, nos
              collaborateurs et notre communauté.
            </p>
            <p>
              Nous sommes passionnément dédiés à l'excellence technologique.
              Nous croyons fermement que la technologie est un moteur de
              progrès, et nous nous efforçons constamment d'atteindre les plus
              hauts standards de qualité. Chaque solution que nous développons
              est le fruit d'une expertise approfondie, de la recherche de la
              perfection et de l'engagement envers l'innovation.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";

import features from "../data/features.json";
import { Link } from "react-router-dom";

export default function Features() {
  return (
    <div class="py-10 bg-light">
      <div class="container">
        <h4 class="fs-1 fw-bold mb-3 text-center">
          Explorez nos fonctionnalités
        </h4>
        <p class="text-muted text-center">
          Les fonctionnalités essentielles qui font la différence au quotidien.
        </p>
        <div class="row gx-10 gy-7 mt-4">
          {features.entries.map((feature, index) => (
            <div
              key={index}
              class="col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-start"
            >
              <picture class="d-block f-h-20">
                <img
                  className="h-100 w-auto"
                  src={`${process.env.PUBLIC_URL}/assets/images/${feature.img}`}
                  alt=""
                />
              </picture>
              <p class="fw-medium mb-1 mt-3 fs-5">{feature.title}</p>
              <span class="text-muted fs-7">
                {feature.description.length > 200
                  ? `${feature.description.substring(0, 200)} ....`
                  : feature.description}
              </span>
              <Link
                to={`/features/${feature.id}`}
                class="btn btn-link text-start px-0 me-3 fw-medium text-decoration-none mt-4"
              >
                En savoir plus &rarr;
              </Link>
            </div>
          ))}
        </div>

        <Link
          to={`/features/invoicing`}
          class="btn btn-primary d-table mx-auto mt-5 w-100 w-md-auto mt-lg-8 mb-3"
          role="button"
        >
          Découvrez toutes les fonctionnalités
        </Link>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { SVGShapeEight } from "../svg/svg-shape-eight";
import { SVGShapeThree } from "../svg/svg-shape-three";
import HighlightItem from "../components/HighlightItem";
import { useNavigate, useParams } from "react-router-dom";

import features from "../data/features.json";

export default function FeaturePage() {
  const id = useParams().name;
  const navigate = useNavigate();
  const [selected, setselected] = useState(id);

  return (
    <section>
      <header className="bg-primary py-10 text-white overflow-hidden position-relative z-index-10">
        <div className="container">
          <div className="f-w-72 position-absolute top-n25 start-n13 opacity-10 d-none d-lg-block">
            <span className="d-block">
              <SVGShapeEight
                colorOne={"text-success"}
                colorTwo={"text-success"}
              />
            </span>
          </div>
          <div className="f-w-72 position-absolute bottom-10 end-n3 opacity-10 d-none d-lg-block">
            <span className="d-block">
              <SVGShapeThree
                colorOne={"text-success"}
                colorTwo={"text-success"}
              />
            </span>
          </div>
          <div className="text-center col-12 col-sm-9 col-lg-7 col-xl-6 mx-auto pb-8 position-relative z-index-20">
            <h1 className="display-3 fw-bold mb-3">Fonctionnalités</h1>
            <p className="opacity-75 lead">
              Explorez un monde de possibilités grâce à nos fonctionnalités
              exceptionnelles, intuitives conçues pour simplifier votre vie et
              révolutionnent l'expérience utilisateur
            </p>
          </div>
        </div>
      </header>

      <div className="container position-relative z-index-20">
        <div className="row border mt-n10 bg-white shadow-lg">
          {/* Features tabs */}
          <ul class="tab-nav nav nav-pills mb-3" id="pills-tab" role="tablist">
            {features.entries.map((feature, index) => (
              <li
                key={index}
                class="nav-item"
                role="presentation"
                onClick={() => navigate(`/features/${feature.id}`)}
              >
                <button
                  class={`nav-link ${selected === feature.id ? "active" : ""}`}
                  id={`pills-${feature.id}-tab`}
                  data-bs-toggle="pill"
                  data-bs-target={`#pills-${feature.id}`}
                  type="button"
                  role="tab"
                  aria-controls={`pills-${feature.id}`}
                  aria-selected="true"
                >
                  {feature.title}
                </button>
              </li>
            ))}
          </ul>
          <div class="tab-content" id="pills-tabContent">
            {features.entries.map((feature, index) => (
              <div
                key={index}
                class={`tab-pane fade ${
                  selected === feature.id ? "show active" : ""
                }`}
                id={`pills-${feature.id}`}
                role="tabpanel"
                aria-labelledby={`pills-${feature.id}-tab`}
                tabindex="0"
              >
                <HighlightItem
                  id={feature.id}
                  img={feature.img}
                  subtitle={feature.subtitle}
                  title={feature.title}
                  description={feature.description}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export const SVGSHapeNine = () => (
  <svg
    class="w-100"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 411.39 411.39"
  >
    <circle cx="355.89" cy="255.89" r="2.5" fill="none" />
    <circle cx="355.89" cy="55.89" r="2.5" fill="none" />
    <circle cx="305.89" cy="105.89" r="2.5" fill="none" />
    <circle cx="255.89" cy="155.89" r="2.5" fill="none" />
    <circle cx="305.89" cy="205.89" r="2.5" fill="none" />
    <circle cx="305.89" cy="405.89" r="2.5" fill="none" />
    <circle cx="405.89" cy="5.89" r="2.5" fill="none" />
    <circle cx="305.89" cy="305.89" r="2.5" fill="none" />
    <circle cx="205.89" cy="105.89" r="2.5" fill="none" />
    <circle cx="255.89" cy="255.89" r="2.5" fill="none" />
    <circle cx="205.89" cy="205.89" r="2.5" fill="none" />
    <circle cx="305.89" cy="5.89" r="2.5" fill="none" />
    <circle cx="355.89" cy="355.89" r="2.5" fill="none" />
    <circle cx="205.89" cy="405.89" r="2.5" fill="none" />
    <circle cx="205.89" cy="305.89" r="2.5" fill="none" />
    <circle cx="355.89" cy="155.89" r="2.5" fill="none" />
    <circle cx="405.89" cy="405.89" r="2.5" fill="none" />
    <circle cx="405.89" cy="205.89" r="2.5" fill="none" />
    <circle cx="405.89" cy="305.89" r="2.5" fill="none" />
    <circle cx="405.89" cy="105.89" r="2.5" fill="none" />
    <circle cx="5.89" cy="305.89" r="2.5" fill="none" />
    <circle cx="5.89" cy="5.89" r="2.5" fill="none" />
    <circle cx="55.89" cy="55.89" r="2.5" fill="none" />
    <circle cx="55.89" cy="155.89" r="2.5" fill="none" />
    <circle cx="55.89" cy="355.89" r="2.5" fill="none" />
    <circle cx="55.89" cy="255.89" r="2.5" fill="none" />
    <circle cx="155.89" cy="55.89" r="2.5" fill="none" />
    <circle cx="105.89" cy="305.89" r="2.5" fill="none" />
    <circle cx="105.89" cy="205.89" r="2.5" fill="none" />
    <circle cx="105.89" cy="105.89" r="2.5" fill="none" />
    <circle cx="205.89" cy="5.89" r="2.5" fill="none" />
    <circle cx="255.89" cy="355.89" r="2.5" fill="none" />
    <circle cx="105.89" cy="405.89" r="2.5" fill="none" />
    <circle cx="255.89" cy="55.89" r="2.5" fill="none" />
    <circle cx="105.89" cy="5.89" r="2.5" fill="none" />
    <circle cx="5.89" cy="205.89" r="2.5" fill="none" />
    <circle cx="5.89" cy="405.89" r="2.5" fill="none" />
    <circle cx="155.89" cy="355.89" r="2.5" fill="none" />
    <circle cx="155.89" cy="255.89" r="2.5" fill="none" />
    <circle cx="155.89" cy="155.89" r="2.5" fill="none" />
    <circle cx="5.89" cy="105.89" r="2.5" fill="none" />
    <path
      d="M5.89,11.39a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,5.89,11.39Zm0-8a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,5.89,3.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M55.89,50.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,55.89,50.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,55.89,58.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M155.89,61.39a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,155.89,61.39Zm0-8a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,155.89,53.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M255.89,50.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,255.89,50.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,255.89,58.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M355.89,50.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,355.89,50.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,355.89,58.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M55.89,150.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,55.89,150.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,55.89,158.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M155.89,161.39a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,155.89,161.39Zm0-8a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,155.89,153.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M255.89,150.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,255.89,150.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,255.89,158.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M355.89,150.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,355.89,150.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,355.89,158.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M55.89,250.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,55.89,250.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,55.89,258.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M155.89,261.39a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,155.89,261.39Zm0-8a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,155.89,253.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M255.89,250.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,255.89,250.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,255.89,258.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M355.89,250.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,355.89,250.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,355.89,258.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M55.89,350.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,55.89,350.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,55.89,358.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M155.89,361.39a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,155.89,361.39Zm0-8a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,155.89,353.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M255.89,350.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,255.89,350.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,255.89,358.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M355.89,350.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,355.89,350.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,355.89,358.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M105.89,11.39a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,105.89,11.39Zm0-8a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,105.89,3.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M205.89,11.39a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,205.89,11.39Zm0-8a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,205.89,3.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M305.89,11.39a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,305.89,11.39Zm0-8a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,305.89,3.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M405.89,11.39a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,405.89,11.39Zm0-8a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,405.89,3.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M5.89,100.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,5.89,100.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,5.89,108.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M105.89,100.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,105.89,100.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,105.89,108.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M205.89,100.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,205.89,100.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,205.89,108.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M305.89,100.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,305.89,100.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,305.89,108.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M405.89,100.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,405.89,100.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,405.89,108.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M5.89,200.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,5.89,200.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,5.89,208.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M105.89,200.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,105.89,200.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,105.89,208.39Z"
      fill="#bcbcbc"
    />
    <path d="M205.89,200.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,205.89,200.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,205.89,208.39Z" />
    <path
      d="M305.89,200.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,305.89,200.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,305.89,208.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M405.89,200.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,405.89,200.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,405.89,208.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M5.89,300.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,5.89,300.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,5.89,308.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M105.89,300.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,105.89,300.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,105.89,308.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M205.89,300.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,205.89,300.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,205.89,308.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M305.89,300.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,305.89,300.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,305.89,308.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M405.89,300.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,405.89,300.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,405.89,308.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M5.89,400.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,5.89,400.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,5.89,408.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M105.89,400.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,105.89,400.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,105.89,408.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M205.89,400.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,205.89,400.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,205.89,408.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M305.89,400.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,305.89,400.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,305.89,408.39Z"
      fill="#bcbcbc"
    />
    <path
      d="M405.89,400.39a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,405.89,400.39Zm0,8a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,405.89,408.39Z"
      fill="#bcbcbc"
    />
    <rect
      x="152.24"
      y="100.37"
      width="3"
      height="4.75"
      transform="translate(-27.62 138.8) rotate(-45)"
    />
    <rect
      x="157.72"
      y="105.84"
      width="3"
      height="4.75"
      transform="translate(-29.89 144.28) rotate(-45)"
    />
    <rect
      x="151.37"
      y="106.72"
      width="4.75"
      height="3"
      transform="translate(-31.49 140.41) rotate(-45)"
    />
    <rect
      x="156.84"
      y="101.24"
      width="4.75"
      height="3"
      transform="translate(-26.02 142.67) rotate(-45)"
    />
    <rect
      x="154.98"
      y="103.98"
      width="3"
      height="3"
      transform="translate(-28.75 141.54) rotate(-45)"
    />
    <rect
      x="256.72"
      y="105.84"
      width="3"
      height="4.75"
      transform="translate(-0.89 214.28) rotate(-45)"
    />
    <rect
      x="251.24"
      y="100.37"
      width="3"
      height="4.75"
      transform="translate(1.38 208.8) rotate(-45)"
    />
    <rect
      x="255.84"
      y="101.24"
      width="4.75"
      height="3"
      transform="translate(2.98 212.68) rotate(-45)"
    />
    <rect
      x="250.37"
      y="106.72"
      width="4.75"
      height="3"
      transform="translate(-2.5 210.4) rotate(-45)"
    />
    <rect
      x="253.98"
      y="103.98"
      width="3"
      height="3"
      transform="translate(0.25 211.55) rotate(-45)"
    />
    <rect
      x="355.72"
      y="105.84"
      width="3"
      height="4.75"
      transform="translate(28.11 284.29) rotate(-45)"
    />
    <rect
      x="350.24"
      y="100.37"
      width="3"
      height="4.75"
      transform="translate(30.37 278.81) rotate(-45)"
    />
    <rect
      x="354.84"
      y="101.24"
      width="4.75"
      height="3"
      transform="translate(31.98 282.68) rotate(-45)"
    />
    <rect
      x="349.37"
      y="106.72"
      width="4.75"
      height="3"
      transform="translate(26.5 280.4) rotate(-45)"
    />
    <rect
      x="352.98"
      y="103.98"
      width="3"
      height="3"
      transform="translate(29.24 281.55) rotate(-45)"
    />
    <rect
      x="51.24"
      y="100.37"
      width="3"
      height="4.75"
      transform="translate(-57.2 67.38) rotate(-45)"
    />
    <rect
      x="56.72"
      y="105.84"
      width="3"
      height="4.75"
      transform="translate(-59.47 72.86) rotate(-45)"
    />
    <rect
      x="55.84"
      y="101.24"
      width="4.75"
      height="3"
      transform="translate(-55.6 71.26) rotate(-45)"
    />
    <rect
      x="50.37"
      y="106.72"
      width="4.75"
      height="3"
      transform="translate(-61.07 68.98) rotate(-45)"
    />
    <rect
      x="53.98"
      y="103.98"
      width="3"
      height="3"
      transform="translate(-58.34 70.12) rotate(-45)"
    />
    <rect
      x="152.24"
      y="0.37"
      width="3"
      height="4.75"
      transform="translate(43.09 109.51) rotate(-45)"
    />
    <rect
      x="157.72"
      y="5.84"
      width="3"
      height="4.75"
      transform="translate(40.82 114.99) rotate(-45)"
    />
    <rect
      x="156.84"
      y="1.24"
      width="4.75"
      height="3"
      transform="translate(44.7 113.38) rotate(-45)"
    />
    <rect
      x="151.37"
      y="6.72"
      width="4.75"
      height="3"
      transform="translate(39.22 111.11) rotate(-45)"
    />
    <rect
      x="154.98"
      y="3.98"
      width="3"
      height="3"
      transform="translate(41.96 112.25) rotate(-45)"
    />
    <rect
      x="251.24"
      y="0.37"
      width="3"
      height="4.75"
      transform="translate(72.09 179.52) rotate(-45)"
    />
    <rect
      x="256.72"
      y="5.84"
      width="3"
      height="4.75"
      transform="translate(69.82 184.99) rotate(-45)"
    />
    <rect
      x="250.37"
      y="6.72"
      width="4.75"
      height="3"
      transform="translate(68.21 181.11) rotate(-45)"
    />
    <rect
      x="255.84"
      y="1.24"
      width="4.75"
      height="3"
      transform="translate(73.69 183.39) rotate(-45)"
    />
    <rect
      x="253.98"
      y="3.98"
      width="3"
      height="3"
      transform="translate(70.96 182.26) rotate(-45)"
    />
    <rect
      x="350.24"
      y="0.37"
      width="3"
      height="4.75"
      transform="translate(101.09 249.52) rotate(-45)"
    />
    <rect
      x="355.72"
      y="5.84"
      width="3"
      height="4.75"
      transform="translate(98.82 255) rotate(-45)"
    />
    <rect
      x="349.37"
      y="6.72"
      width="4.75"
      height="3"
      transform="translate(97.21 251.12) rotate(-45)"
    />
    <rect
      x="354.84"
      y="1.24"
      width="4.75"
      height="3"
      transform="translate(102.69 253.39) rotate(-45)"
    />
    <rect
      x="352.98"
      y="3.98"
      width="3"
      height="3"
      transform="translate(99.96 252.26) rotate(-45)"
    />
    <rect
      x="1.24"
      y="50.37"
      width="3"
      height="4.75"
      transform="translate(-36.49 17.38) rotate(-45)"
    />
    <rect
      x="6.72"
      y="55.84"
      width="3"
      height="4.75"
      transform="translate(-38.76 22.86) rotate(-45)"
    />
    <rect
      x="0.37"
      y="56.72"
      width="4.75"
      height="3"
      transform="translate(-40.36 18.99) rotate(-45)"
    />
    <rect
      x="5.84"
      y="51.24"
      width="4.75"
      height="3"
      transform="translate(-34.89 21.26) rotate(-45)"
    />
    <rect
      x="3.98"
      y="53.98"
      width="3"
      height="3"
      transform="translate(-37.62 20.12) rotate(-45)"
    />
    <rect
      x="101.24"
      y="50.37"
      width="3"
      height="4.75"
      transform="translate(-7.2 88.09) rotate(-45)"
    />
    <rect
      x="106.72"
      y="55.84"
      width="3"
      height="4.75"
      transform="translate(-9.47 93.57) rotate(-45)"
    />
    <rect
      x="105.84"
      y="51.24"
      width="4.75"
      height="3"
      transform="translate(-5.6 91.97) rotate(-45)"
    />
    <rect
      x="100.37"
      y="56.72"
      width="4.75"
      height="3"
      transform="translate(-11.07 89.69) rotate(-45)"
    />
    <rect
      x="103.98"
      y="53.98"
      width="3"
      height="3"
      transform="translate(-8.33 90.84) rotate(-45)"
    />
    <rect
      x="206.72"
      y="55.84"
      width="3"
      height="4.75"
      transform="translate(19.82 164.28) rotate(-45)"
    />
    <rect
      x="201.24"
      y="50.37"
      width="3"
      height="4.75"
      transform="translate(22.09 158.8) rotate(-45)"
    />
    <rect
      x="200.37"
      y="56.72"
      width="4.75"
      height="3"
      transform="translate(18.21 160.4) rotate(-45)"
    />
    <rect
      x="205.84"
      y="51.24"
      width="4.75"
      height="3"
      transform="translate(23.69 162.68) rotate(-45)"
    />
    <rect
      x="203.98"
      y="53.98"
      width="3"
      height="3"
      transform="translate(20.96 161.55) rotate(-45)"
    />
    <rect
      x="301.24"
      y="50.37"
      width="3"
      height="4.75"
      transform="translate(51.38 229.52) rotate(-45)"
    />
    <rect
      x="306.72"
      y="55.84"
      width="3"
      height="4.75"
      transform="translate(49.11 234.99) rotate(-45)"
    />
    <rect
      x="300.37"
      y="56.72"
      width="4.75"
      height="3"
      transform="translate(47.5 231.11) rotate(-45)"
    />
    <rect
      x="305.84"
      y="51.24"
      width="4.75"
      height="3"
      transform="translate(52.98 233.39) rotate(-45)"
    />
    <rect
      x="303.98"
      y="53.98"
      width="3"
      height="3"
      transform="translate(50.25 232.26) rotate(-45)"
    />
    <rect
      x="406.72"
      y="55.84"
      width="3"
      height="4.75"
      transform="translate(78.4 305.7) rotate(-45)"
    />
    <rect
      x="401.24"
      y="50.37"
      width="3"
      height="4.75"
      transform="translate(80.67 300.23) rotate(-45)"
    />
    <rect
      x="400.37"
      y="56.72"
      width="4.75"
      height="3"
      transform="translate(76.79 301.82) rotate(-45)"
    />
    <rect
      x="405.84"
      y="51.24"
      width="4.75"
      height="3"
      transform="translate(82.27 304.1) rotate(-45)"
    />
    <rect
      x="403.98"
      y="53.98"
      width="3"
      height="3"
      transform="translate(79.54 302.97) rotate(-45)"
    />
    <rect
      x="1.24"
      y="150.37"
      width="3"
      height="4.75"
      transform="translate(-107.2 46.67) rotate(-45)"
    />
    <rect
      x="6.72"
      y="155.84"
      width="3"
      height="4.75"
      transform="translate(-109.47 52.15) rotate(-45)"
    />
    <rect
      x="0.37"
      y="156.72"
      width="4.75"
      height="3"
      transform="translate(-111.07 48.27) rotate(-45)"
    />
    <rect
      x="5.84"
      y="151.24"
      width="4.75"
      height="3"
      transform="translate(-105.6 50.55) rotate(-45)"
    />
    <rect
      x="3.98"
      y="153.98"
      width="3"
      height="3"
      transform="translate(-108.34 49.41) rotate(-45)"
    />
    <rect
      x="101.24"
      y="150.37"
      width="3"
      height="4.75"
      transform="translate(-77.91 117.38) rotate(-45)"
    />
    <rect
      x="106.72"
      y="155.84"
      width="3"
      height="4.75"
      transform="translate(-80.18 122.86) rotate(-45)"
    />
    <rect
      x="100.37"
      y="156.72"
      width="4.75"
      height="3"
      transform="translate(-81.78 118.98) rotate(-45)"
    />
    <rect
      x="105.84"
      y="151.24"
      width="4.75"
      height="3"
      transform="translate(-76.31 121.26) rotate(-45)"
    />
    <rect
      x="103.98"
      y="153.98"
      width="3"
      height="3"
      transform="translate(-79.05 120.13) rotate(-45)"
    />
    <rect
      x="206.72"
      y="155.84"
      width="3"
      height="4.75"
      transform="translate(-50.89 193.57) rotate(-45)"
    />
    <rect
      x="201.24"
      y="150.37"
      width="3"
      height="4.75"
      transform="translate(-48.62 188.09) rotate(-45)"
    />
    <rect
      x="200.37"
      y="156.72"
      width="4.75"
      height="3"
      transform="translate(-52.5 189.69) rotate(-45)"
    />
    <rect
      x="205.84"
      y="151.24"
      width="4.75"
      height="3"
      transform="translate(-47.02 191.97) rotate(-45)"
    />
    <rect
      x="203.98"
      y="153.98"
      width="3"
      height="3"
      transform="translate(-49.76 190.84) rotate(-45)"
    />
    <rect
      x="306.72"
      y="155.84"
      width="3"
      height="4.75"
      transform="translate(-21.6 264.28) rotate(-45)"
    />
    <rect
      x="301.24"
      y="150.37"
      width="3"
      height="4.75"
      transform="translate(-19.33 258.8) rotate(-45)"
    />
    <rect
      x="305.84"
      y="151.24"
      width="4.75"
      height="3"
      transform="translate(-17.73 262.68) rotate(-45)"
    />
    <rect
      x="300.37"
      y="156.72"
      width="4.75"
      height="3"
      transform="translate(-23.21 260.4) rotate(-45)"
    />
    <rect
      x="303.98"
      y="153.98"
      width="3"
      height="3"
      transform="translate(-20.46 261.55) rotate(-45)"
    />
    <rect
      x="406.72"
      y="155.84"
      width="3"
      height="4.75"
      transform="translate(7.69 334.99) rotate(-45)"
    />
    <rect
      x="401.24"
      y="150.37"
      width="3"
      height="4.75"
      transform="translate(9.96 329.52) rotate(-45)"
    />
    <rect
      x="400.37"
      y="156.72"
      width="4.75"
      height="3"
      transform="translate(6.08 331.11) rotate(-45)"
    />
    <rect
      x="405.84"
      y="151.24"
      width="4.75"
      height="3"
      transform="translate(11.56 333.39) rotate(-45)"
    />
    <rect
      x="403.98"
      y="153.98"
      width="3"
      height="3"
      transform="translate(8.83 332.26) rotate(-45)"
    />
    <rect
      x="1.24"
      y="250.37"
      width="3"
      height="4.75"
      transform="translate(-177.91 75.96) rotate(-45)"
    />
    <rect
      x="6.72"
      y="255.84"
      width="3"
      height="4.75"
      transform="translate(-180.18 81.44) rotate(-45)"
    />
    <rect
      x="5.84"
      y="251.24"
      width="4.75"
      height="3"
      transform="translate(-176.31 79.83) rotate(-45)"
    />
    <rect
      x="0.37"
      y="256.72"
      width="4.75"
      height="3"
      transform="translate(-181.78 77.56) rotate(-45)"
    />
    <rect
      x="3.98"
      y="253.98"
      width="3"
      height="3"
      transform="translate(-179.05 78.7) rotate(-45)"
    />
    <rect
      x="101.24"
      y="250.37"
      width="3"
      height="4.75"
      transform="translate(-148.62 146.67) rotate(-45)"
    />
    <rect
      x="106.72"
      y="255.84"
      width="3"
      height="4.75"
      transform="translate(-150.89 152.15) rotate(-45)"
    />
    <rect
      x="105.84"
      y="251.24"
      width="4.75"
      height="3"
      transform="translate(-147.02 150.55) rotate(-45)"
    />
    <rect
      x="100.37"
      y="256.72"
      width="4.75"
      height="3"
      transform="translate(-152.49 148.27) rotate(-45)"
    />
    <rect
      x="103.98"
      y="253.98"
      width="3"
      height="3"
      transform="translate(-149.76 149.42) rotate(-45)"
    />
    <rect
      x="201.24"
      y="250.37"
      width="3"
      height="4.75"
      transform="translate(-119.33 217.38) rotate(-45)"
    />
    <rect
      x="206.72"
      y="255.84"
      width="3"
      height="4.75"
      transform="translate(-121.6 222.86) rotate(-45)"
    />
    <rect
      x="200.37"
      y="256.72"
      width="4.75"
      height="3"
      transform="translate(-123.2 218.98) rotate(-45)"
    />
    <rect
      x="205.84"
      y="251.24"
      width="4.75"
      height="3"
      transform="translate(-117.73 221.26) rotate(-45)"
    />
    <rect
      x="203.98"
      y="253.98"
      width="3"
      height="3"
      transform="translate(-120.47 220.13) rotate(-45)"
    />
    <rect
      x="301.24"
      y="250.37"
      width="3"
      height="4.75"
      transform="translate(-90.04 288.09) rotate(-45)"
    />
    <rect
      x="306.72"
      y="255.84"
      width="3"
      height="4.75"
      transform="translate(-92.31 293.57) rotate(-45)"
    />
    <rect
      x="305.84"
      y="251.24"
      width="4.75"
      height="3"
      transform="translate(-88.44 291.97) rotate(-45)"
    />
    <rect
      x="300.37"
      y="256.72"
      width="4.75"
      height="3"
      transform="translate(-93.92 289.69) rotate(-45)"
    />
    <rect
      x="303.98"
      y="253.98"
      width="3"
      height="3"
      transform="translate(-91.18 290.84) rotate(-45)"
    />
    <rect
      x="401.24"
      y="250.37"
      width="3"
      height="4.75"
      transform="translate(-60.75 358.8) rotate(-45)"
    />
    <rect
      x="406.72"
      y="255.84"
      width="3"
      height="4.75"
      transform="translate(-63.02 364.28) rotate(-45)"
    />
    <rect
      x="400.37"
      y="256.72"
      width="4.75"
      height="3"
      transform="translate(-64.63 360.39) rotate(-45)"
    />
    <rect
      x="405.84"
      y="251.24"
      width="4.75"
      height="3"
      transform="translate(-59.15 362.68) rotate(-45)"
    />
    <rect
      x="403.98"
      y="253.98"
      width="3"
      height="3"
      transform="translate(-61.89 361.55) rotate(-45)"
    />
    <rect
      x="6.72"
      y="355.84"
      width="3"
      height="4.75"
      transform="translate(-250.89 110.73) rotate(-45)"
    />
    <rect
      x="1.24"
      y="350.37"
      width="3"
      height="4.75"
      transform="translate(-248.62 105.25) rotate(-45)"
    />
    <rect
      x="0.37"
      y="356.72"
      width="4.75"
      height="3"
      transform="translate(-252.49 106.85) rotate(-45)"
    />
    <rect
      x="5.84"
      y="351.24"
      width="4.75"
      height="3"
      transform="translate(-247.02 109.12) rotate(-45)"
    />
    <rect
      x="3.98"
      y="353.98"
      width="3"
      height="3"
      transform="translate(-249.76 108) rotate(-45)"
    />
    <rect
      x="106.72"
      y="355.84"
      width="3"
      height="4.75"
      transform="translate(-221.6 181.44) rotate(-45)"
    />
    <rect
      x="101.24"
      y="350.37"
      width="3"
      height="4.75"
      transform="translate(-219.33 175.96) rotate(-45)"
    />
    <rect
      x="100.37"
      y="356.72"
      width="4.75"
      height="3"
      transform="translate(-223.2 177.56) rotate(-45)"
    />
    <rect
      x="105.84"
      y="351.24"
      width="4.75"
      height="3"
      transform="translate(-217.73 179.83) rotate(-45)"
    />
    <rect
      x="103.98"
      y="353.98"
      width="3"
      height="3"
      transform="translate(-220.47 178.71) rotate(-45)"
    />
    <rect
      x="206.72"
      y="355.84"
      width="3"
      height="4.75"
      transform="translate(-192.31 252.15) rotate(-45)"
    />
    <rect
      x="201.24"
      y="350.37"
      width="3"
      height="4.75"
      transform="translate(-190.04 246.67) rotate(-45)"
    />
    <rect
      x="205.84"
      y="351.24"
      width="4.75"
      height="3"
      transform="translate(-188.44 250.55) rotate(-45)"
    />
    <rect
      x="200.37"
      y="356.72"
      width="4.75"
      height="3"
      transform="translate(-193.91 248.26) rotate(-45)"
    />
    <rect
      x="203.98"
      y="353.98"
      width="3"
      height="3"
      transform="translate(-191.18 249.42) rotate(-45)"
    />
    <rect
      x="301.24"
      y="350.37"
      width="3"
      height="4.75"
      transform="translate(-160.75 317.38) rotate(-45)"
    />
    <rect
      x="306.72"
      y="355.84"
      width="3"
      height="4.75"
      transform="translate(-163.02 322.86) rotate(-45)"
    />
    <rect
      x="300.37"
      y="356.72"
      width="4.75"
      height="3"
      transform="translate(-164.63 318.97) rotate(-45)"
    />
    <rect
      x="305.84"
      y="351.24"
      width="4.75"
      height="3"
      transform="translate(-159.15 321.26) rotate(-45)"
    />
    <rect
      x="303.98"
      y="353.98"
      width="3"
      height="3"
      transform="translate(-161.89 320.13) rotate(-45)"
    />
    <rect
      x="406.72"
      y="355.84"
      width="3"
      height="4.75"
      transform="translate(-133.73 393.57) rotate(-45)"
    />
    <rect
      x="401.24"
      y="350.37"
      width="3"
      height="4.75"
      transform="translate(-131.46 388.09) rotate(-45)"
    />
    <rect
      x="405.84"
      y="351.24"
      width="4.75"
      height="3"
      transform="translate(-129.86 391.97) rotate(-45)"
    />
    <rect
      x="400.37"
      y="356.72"
      width="4.75"
      height="3"
      transform="translate(-135.34 389.68) rotate(-45)"
    />
    <rect
      x="403.98"
      y="353.98"
      width="3"
      height="3"
      transform="translate(-132.6 390.85) rotate(-45)"
    />
    <rect
      x="51.24"
      y="0.37"
      width="3"
      height="4.75"
      transform="translate(13.51 38.09) rotate(-45)"
    />
    <rect
      x="56.72"
      y="5.84"
      width="3"
      height="4.75"
      transform="translate(11.24 43.57) rotate(-45)"
    />
    <rect
      x="55.84"
      y="1.24"
      width="4.75"
      height="3"
      transform="translate(15.11 41.97) rotate(-45)"
    />
    <rect
      x="50.37"
      y="6.72"
      width="4.75"
      height="3"
      transform="translate(9.64 39.7) rotate(-45)"
    />
    <rect
      x="53.98"
      y="3.98"
      width="3"
      height="3"
      transform="translate(12.38 40.83) rotate(-45)"
    />
    <rect
      x="152.24"
      y="200.37"
      width="3"
      height="4.75"
      transform="translate(-98.33 168.09) rotate(-45)"
    />
    <rect
      x="157.72"
      y="205.84"
      width="3"
      height="4.75"
      transform="translate(-100.6 173.57) rotate(-45)"
    />
    <rect
      x="151.37"
      y="206.72"
      width="4.75"
      height="3"
      transform="translate(-102.2 169.69) rotate(-45)"
    />
    <rect
      x="156.84"
      y="201.24"
      width="4.75"
      height="3"
      transform="translate(-96.72 171.95) rotate(-45)"
    />
    <rect
      x="154.98"
      y="203.98"
      width="3"
      height="3"
      transform="translate(-99.46 170.83) rotate(-45)"
    />
    <rect
      x="256.72"
      y="205.84"
      width="3"
      height="4.75"
      transform="translate(-71.6 243.57) rotate(-45)"
    />
    <rect
      x="251.24"
      y="200.37"
      width="3"
      height="4.75"
      transform="translate(-69.33 238.09) rotate(-45)"
    />
    <rect
      x="250.37"
      y="206.72"
      width="4.75"
      height="3"
      transform="translate(-73.21 239.69) rotate(-45)"
    />
    <rect
      x="255.84"
      y="201.24"
      width="4.75"
      height="3"
      transform="translate(-67.73 241.97) rotate(-45)"
    />
    <rect
      x="253.98"
      y="203.98"
      width="3"
      height="3"
      transform="translate(-70.47 240.84) rotate(-45)"
    />
    <rect
      x="350.24"
      y="200.37"
      width="3"
      height="4.75"
      transform="translate(-40.34 308.1) rotate(-45)"
    />
    <rect
      x="355.72"
      y="205.84"
      width="3"
      height="4.75"
      transform="translate(-42.6 313.57) rotate(-45)"
    />
    <rect
      x="354.84"
      y="201.24"
      width="4.75"
      height="3"
      transform="translate(-38.73 311.97) rotate(-45)"
    />
    <rect
      x="349.37"
      y="206.72"
      width="4.75"
      height="3"
      transform="translate(-44.21 309.69) rotate(-45)"
    />
    <rect
      x="352.98"
      y="203.98"
      width="3"
      height="3"
      transform="translate(-41.47 310.85) rotate(-45)"
    />
    <rect
      x="51.24"
      y="200.37"
      width="3"
      height="4.75"
      transform="translate(-127.91 96.67) rotate(-45)"
    />
    <rect
      x="56.72"
      y="205.84"
      width="3"
      height="4.75"
      transform="translate(-130.18 102.15) rotate(-45)"
    />
    <rect
      x="50.37"
      y="206.72"
      width="4.75"
      height="3"
      transform="translate(-131.78 98.27) rotate(-45)"
    />
    <rect
      x="55.84"
      y="201.24"
      width="4.75"
      height="3"
      transform="translate(-126.31 100.55) rotate(-45)"
    />
    <rect
      x="53.98"
      y="203.98"
      width="3"
      height="3"
      transform="translate(-129.05 99.42) rotate(-45)"
    />
    <rect
      x="152.24"
      y="300.37"
      width="3"
      height="4.75"
      transform="translate(-169.04 197.38) rotate(-45)"
    />
    <rect
      x="157.72"
      y="305.84"
      width="3"
      height="4.75"
      transform="translate(-171.31 202.86) rotate(-45)"
    />
    <rect
      x="156.84"
      y="301.24"
      width="4.75"
      height="3"
      transform="translate(-167.43 201.24) rotate(-45)"
    />
    <rect
      x="151.37"
      y="306.72"
      width="4.75"
      height="3"
      transform="translate(-172.91 198.98) rotate(-45)"
    />
    <rect
      x="154.98"
      y="303.98"
      width="3"
      height="3"
      transform="translate(-170.17 200.12) rotate(-45)"
    />
    <rect
      x="256.72"
      y="305.84"
      width="3"
      height="4.75"
      transform="translate(-142.31 272.86) rotate(-45)"
    />
    <rect
      x="251.24"
      y="300.37"
      width="3"
      height="4.75"
      transform="translate(-140.04 267.38) rotate(-45)"
    />
    <rect
      x="250.37"
      y="306.72"
      width="4.75"
      height="3"
      transform="translate(-143.91 268.98) rotate(-45)"
    />
    <rect
      x="255.84"
      y="301.24"
      width="4.75"
      height="3"
      transform="translate(-138.44 271.26) rotate(-45)"
    />
    <rect
      x="253.98"
      y="303.98"
      width="3"
      height="3"
      transform="translate(-141.18 270.13) rotate(-45)"
    />
    <rect
      x="355.72"
      y="305.84"
      width="3"
      height="4.75"
      transform="translate(-113.32 342.86) rotate(-45)"
    />
    <rect
      x="350.24"
      y="300.37"
      width="3"
      height="4.75"
      transform="translate(-111.05 337.39) rotate(-45)"
    />
    <rect
      x="349.37"
      y="306.72"
      width="4.75"
      height="3"
      transform="translate(-114.92 338.98) rotate(-45)"
    />
    <rect
      x="354.84"
      y="301.24"
      width="4.75"
      height="3"
      transform="translate(-109.44 341.26) rotate(-45)"
    />
    <rect
      x="352.98"
      y="303.98"
      width="3"
      height="3"
      transform="translate(-112.18 340.14) rotate(-45)"
    />
    <rect
      x="56.72"
      y="305.84"
      width="3"
      height="4.75"
      transform="translate(-200.89 131.44) rotate(-45)"
    />
    <rect
      x="51.24"
      y="300.37"
      width="3"
      height="4.75"
      transform="translate(-198.62 125.96) rotate(-45)"
    />
    <rect
      x="55.84"
      y="301.24"
      width="4.75"
      height="3"
      transform="translate(-197.02 129.83) rotate(-45)"
    />
    <rect
      x="50.37"
      y="306.72"
      width="4.75"
      height="3"
      transform="translate(-202.49 127.56) rotate(-45)"
    />
    <rect
      x="53.98"
      y="303.98"
      width="3"
      height="3"
      transform="translate(-199.76 128.71) rotate(-45)"
    />
    <rect
      x="157.72"
      y="405.84"
      width="3"
      height="4.75"
      transform="translate(-242.02 232.15) rotate(-45)"
    />
    <rect
      x="152.24"
      y="400.37"
      width="3"
      height="4.75"
      transform="translate(-239.75 226.67) rotate(-45)"
    />
    <rect
      x="151.37"
      y="406.72"
      width="4.75"
      height="3"
      transform="translate(-243.62 228.27) rotate(-45)"
    />
    <rect
      x="156.84"
      y="401.24"
      width="4.75"
      height="3"
      transform="translate(-238.14 230.52) rotate(-45)"
    />
    <rect
      x="154.98"
      y="403.98"
      width="3"
      height="3"
      transform="translate(-240.88 229.41) rotate(-45)"
    />
    <rect
      x="256.72"
      y="405.84"
      width="3"
      height="4.75"
      transform="translate(-213.02 302.15) rotate(-45)"
    />
    <rect
      x="251.24"
      y="400.37"
      width="3"
      height="4.75"
      transform="translate(-210.75 296.67) rotate(-45)"
    />
    <rect
      x="250.37"
      y="406.72"
      width="4.75"
      height="3"
      transform="translate(-214.62 298.26) rotate(-45)"
    />
    <rect
      x="255.84"
      y="401.24"
      width="4.75"
      height="3"
      transform="translate(-209.15 300.55) rotate(-45)"
    />
    <rect
      x="253.98"
      y="403.98"
      width="3"
      height="3"
      transform="translate(-211.89 299.42) rotate(-45)"
    />
    <rect
      x="350.24"
      y="400.37"
      width="3"
      height="4.75"
      transform="translate(-181.76 366.68) rotate(-45)"
    />
    <rect
      x="355.72"
      y="405.84"
      width="3"
      height="4.75"
      transform="translate(-184.03 372.15) rotate(-45)"
    />
    <rect
      x="349.37"
      y="406.72"
      width="4.75"
      height="3"
      transform="translate(-185.63 368.26) rotate(-45)"
    />
    <rect
      x="354.84"
      y="401.24"
      width="4.75"
      height="3"
      transform="translate(-180.15 370.55) rotate(-45)"
    />
    <rect
      x="352.98"
      y="403.98"
      width="3"
      height="3"
      transform="translate(-182.89 369.43) rotate(-45)"
    />
    <rect
      x="51.24"
      y="400.37"
      width="3"
      height="4.75"
      transform="translate(-269.33 155.25) rotate(-45)"
    />
    <rect
      x="56.72"
      y="405.84"
      width="3"
      height="4.75"
      transform="translate(-271.6 160.73) rotate(-45)"
    />
    <rect
      x="50.37"
      y="406.72"
      width="4.75"
      height="3"
      transform="translate(-273.2 156.85) rotate(-45)"
    />
    <rect
      x="55.84"
      y="401.24"
      width="4.75"
      height="3"
      transform="translate(-267.73 159.12) rotate(-45)"
    />
    <rect
      x="53.98"
      y="403.98"
      width="3"
      height="3"
      transform="translate(-270.47 158) rotate(-45)"
    />
  </svg>
);

import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import AppLayout from "./components/AppLayout";
import ContactPage from "./pages/ContactPage";
import PricingPage from "./pages/PricingPage";
import FeaturePage from "./pages/FeaturePage";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route path="" element={<HomePage />} />
        <Route path="features/:name" element={<FeaturePage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="pricing" element={<PricingPage />} />
      </Route>
    </Routes>
  );
}

import React from "react";

export default function FAQ() {
  return (
    <section>
      <div className="py-10">
        <div className="container">
          <h3 className="text-center fs-1 mb-3 fw-bold">
            Questions fréquemment posées
          </h3>
          <div className="mx-auto mt-5 col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="accordion border-0" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header border-bottom" id="headingOne">
                  <button
                    className="accordion-button fw-medium collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Puis-je utiliser Invoice92 pour suivre les reçus ?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Dites adieu aux reçus décolorés éparpillés dans votre espace
                    de travail. Avec Invoice92, le suivi des reçus est simple :
                    vous pouvez facilement prendre une photo d'un reçu et
                    l'ajouter à une dépense.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header border-bottom" id="headingTwo">
                  <button
                    className="accordion-button fw-medium collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Invoice92 peut-il m'aider à suivre mes dépenses lors de mes
                    déplacements ?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Peu importe où votre entreprise vous mène, les applications
                    mobiles Invoice92 vous permettent de suivre sans effort vos
                    dépenses et de surveiller de plus près vos résultats.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header border-bottom"
                  id="headingThree"
                >
                  <button
                    className="accordion-button fw-medium collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Invoice92 propose-t-il des notes de frais utiles pour la
                    période des impôts ?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    La période des impôts est officiellement un jeu d'enfant
                    avec Invoice92. Vous pouvez rédiger des notes de frais en
                    quelques clics et les partager avec votre comptable.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header border-bottom" id="headingFour">
                  <button
                    className="accordion-button fw-medium collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Comment puis-je suivre mes dépenses avec Invoice92 ?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    The best part about Invoice92 is that it keeps track of your
                    business billing and expense info for you. You can connect
                    your bank to automatically import expenses or quickly add
                    them yourself, then keep track of business spending through
                    your account dashboard or handy reports.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";

export default function Footer() {
  return (
    <footer class="bg-dark pt-10 pb-8">
      <div className="container">
        <div class="container">
          <div class="border-bottom border-white-10 pb-7">
            <div class="col-12 col-md-8 col-lg-6 d-flex mx-auto flex-column align-items-center justify-content-center">
              <h2 class="fs-1 fw-bolder text-white text-center">
                Essayez gratuitement pendant 30 jours avec des fonctionnalités
                illimitées.
              </h2>
              <a
                class="btn btn-primary mt-3 w-100 w-md-auto"
                href="http://app.invoice92.com/register"
                role="button"
              >
                Essayer gratuitement
              </a>
            </div>
          </div>
        </div>
        <div class="container pt-7">
          <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-center">
            <a
              class="d-flex align-items-center lh-1 text-white transition-opacity opacity-50-hover text-decoration-none mb-4 mb-md-0"
              href="#"
            >
              <span className="fw-bold fs-2 text-primary">Invoice92</span>
            </a>
            <ul class="list-unstyled d-flex align-items-center justify-content-end">
              <li class="ms-5">
                <a
                  class="text-white text-decoration-none opacity-50-hover transition-opacity"
                  href="#"
                >
                  <i class="ri-facebook-circle-line ri-lg"></i>
                </a>
              </li>
              <li class="ms-5">
                <a
                  class="text-white text-decoration-none opacity-50-hover transition-opacity"
                  href="#"
                >
                  <i class="ri-twitter-line ri-lg"></i>
                </a>
              </li>
              <li class="ms-5">
                <a
                  class="text-white text-decoration-none opacity-50-hover transition-opacity"
                  href="#"
                >
                  <i class="ri-instagram-line ri-lg"></i>
                </a>
              </li>
              <li class="ms-5">
                <a
                  class="text-white text-decoration-none opacity-50-hover transition-opacity"
                  href="#"
                >
                  <i class="ri-snapchat-line ri-lg"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="container">
          <div class="d-flex flex-wrap justify-content-between mt-5 mt-lg-7">
            <div class="w-100 w-sm-50 w-lg-auto mb-4 mb-lg-0">
              <h6 class="footer-heading">Produits</h6>
              <ul class="footer-nav list-unstyled">
                <li>
                  <a href="#">Invoice92</a>
                </li>
                <li>
                  <a href="#">Telmed</a>
                </li>
                <li>
                  <a href="#">My fatihoune</a>
                </li>
              </ul>
            </div>
            <div class="w-100 w-sm-50 w-lg-auto mb-4 mb-lg-0">
              <h6 class="footer-heading">Société</h6>
              <ul class="footer-nav list-unstyled">
                <li>
                  <a href="#">Apropos de nous</a>
                </li>
                <li>
                  <a href="#">Nos valeurs</a>
                </li>
                <li>
                  <a href="#">Rejoignez nous</a>
                </li>
              </ul>
            </div>
            <div class="w-100 w-sm-50 w-lg-auto mb-4 mb-lg-0">
              <h6 class="footer-heading">Navigation</h6>
              <ul class="footer-nav list-unstyled">
                <li>
                  <a href="#">Commençons</a>
                </li>
                <li>
                  <a href="#">Se connecter</a>
                </li>
                <li>
                  <a href="#">S'enregistrer</a>
                </li>
                <li>
                  <a href="#">Aide & soutien</a>
                </li>
                <li>
                  <a href="#">Contactez nous</a>
                </li>
              </ul>
            </div>
            <div class="w-100 w-sm-50 w-lg-auto mb-4 mb-lg-0">
              <h6 class="footer-heading">Mentions légales</h6>
              <ul class="footer-nav list-unstyled">
                <li>
                  <a href="#">Politique de confidentialité</a>
                </li>
                <li>
                  <a href="#">Termes et conditions</a>
                </li>
                <li>
                  <a href="#">Politique de cookies</a>
                </li>
                <li>
                  <a href="#">Politique de remboursement</a>
                </li>
                <li>
                  <a href="#">Juridique de l'entreprises</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="border-top pt-6 mt-7 border-white-10 d-flex flex-column flex-md-row justify-content-between align-items-center">
            <span class="small text-white opacity-50 mb-2 mb-md-0">
              All rights reserved &copy FATIHOUNE S.A.R.L 2021
            </span>
            <span class="small text-white opacity-50">
              Conditions d'utilisation | Politique de sécurité
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

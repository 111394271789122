import React from "react";
import { Tooltip } from "bootstrap";

import { SVGShapeEight } from "../svg/svg-shape-eight";
import { SVGShapeThree } from "../svg/svg-shape-three";

import pricingOne from "../data/pricing-one.json";
import pricingTwo from "../data/pricing-two.json";
import pricingThree from "../data/pricing-three.json";
import pricingFour from "../data/pricing-four.json";
import { Link } from "react-router-dom";

export default function PricingPage() {
  (function () {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  })();

  return (
    <section>
      <header className="bg-primary py-10 text-white overflow-hidden position-relative z-index-10">
        <div className="container">
          <div className="f-w-72 position-absolute top-n25 start-n13 opacity-10 d-none d-lg-block">
            <span className="d-block">
              <SVGShapeEight
                colorOne={"text-success"}
                colorTwo={"text-success"}
              />
            </span>
          </div>
          <div className="f-w-72 position-absolute bottom-10 end-n3 opacity-10 d-none d-lg-block">
            <span className="d-block">
              <SVGShapeThree
                colorOne={"text-success"}
                colorTwo={"text-success"}
              />
            </span>
          </div>
          <div className="text-center col-12 col-sm-9 col-lg-7 col-xl-6 mx-auto pb-8 position-relative z-index-20">
            <h1 className="display-3 fw-bold mb-3">Choisissez un forfait</h1>
            <p className="opacity-75 lead">
              Essayez Invoice92 gratuitement pendant 30 jours avec toutes les
              fonctionnalités, sans carte de crédit requis, aucun engagement
              requis. Ensuite, mettez à niveau votre compte après ça quand tu
              seras prêt.
            </p>
          </div>
        </div>
      </header>

      {/* Pricing Table */}
      <div className="container position-relative z-index-20">
        <div className="row border mt-n10 bg-white shadow-lg">
          {/* First table */}
          <div className="col-3 d-none d-lg-block border-end">
            {/* Col header */}
            <div className="pricing-table-heading">
              <div>
                <h3 className="fs-1 fw-black">Custom</h3>
                <p className="display-4 lh-1 fw-black mb-1">
                  <sup className="fs-9 align-super me-1">F.CFA</sup>N
                  <span className="fs-5 fw-medium">/mo</span>
                </p>
              </div>
              <Link to="/contact" className="btn btn-secondary w-100 mt-4">
                Contactez-nous
              </Link>
            </div>
            {/* Col rows */}
            {pricingOne.entries.map(({ tooltip, text, additional_height }) => (
              <div
                class={`${
                  additional_height ? `f-h-24` : null
                } pricing-table-row justify-content-start`}
              >
                <span
                  class="tooltip-trigger"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={tooltip}
                >
                  {text}
                </span>
              </div>
            ))}
          </div>

          {/* Second table */}
          <div className="col-12 col-md-6 col-lg-3">
            {/* Col header */}
            <div className="pricing-table-heading">
              <div>
                <h3 className="fs-1 fw-black">Lite</h3>
                <p className="display-4 lh-1 fw-black mb-1">
                  <sup className="fs-9 align-super me-1">CFA</sup> 9.900
                  <span className="fs-5 fw-medium">/mo</span>
                </p>
              </div>
              <Link
                to="https://app.invoice92.com/register?plan=price_1OmcBZJk9RmR1lATgk1dyUXW"
                className="btn btn-primary w-100 mt-4"
              >
                Acheter
              </Link>
            </div>
            {/* Col rows */}
            {pricingTwo.entries.map(
              ({ cta, text, suffix, tooltip, additional_height }) => (
                <div
                  class={`${
                    additional_height ? `f-h-24` : null
                  } pricing-table-row`}
                >
                  {cta ? (
                    <a href="#" class="btn btn-primary w-100">
                      {text}
                    </a>
                  ) : (
                    <span
                      class="tooltip-trigger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={tooltip}
                    >
                      {text} <span class="d-lg-none d-inline">{suffix}</span>
                    </span>
                  )}
                </div>
              )
            )}
          </div>

          {/* Second table */}
          <div className="col-12 col-md-6 col-lg-3 border-start">
            {/* Col header */}
            <div className="pricing-table-heading">
              <div>
                <h3 className="fs-1 fw-black">Plus</h3>
                <p className="display-4 lh-1 fw-black mb-1">
                  <sup className="fs-9 align-super me-1">F.CFA</sup>
                   19.900
                  <span className="fs-5 fw-medium">/mo</span>
                </p>
              </div>
              <Link
                to="https://app.invoice92.com/register?plan=price_1OmcBZJk9RmR1lAT0fbCNlpv"
                className="btn btn-primary w-100 mt-4"
              >
                Acheter
              </Link>
            </div>
            {/* Col rows */}
            {pricingThree.entries.map(
              ({ cta, text, suffix, tooltip, additional_height }) => (
                <div
                  class={`${
                    additional_height ? `f-h-24` : null
                  } pricing-table-row`}
                >
                  {cta ? (
                    <a href="#" class="btn btn-primary w-100">
                      {text}
                    </a>
                  ) : (
                    <span
                      class="tooltip-trigger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={tooltip}
                    >
                      {text} <span class="d-lg-none d-inline">{suffix}</span>
                    </span>
                  )}
                </div>
              )
            )}
          </div>

          {/* Third table */}
          <div className="col-12 col-md-6 col-lg-3 border-start mt-8 mt-md-0">
            {/* Col header */}
            <div className="pricing-table-heading position-relative">
              <div>
                <h3 className="fs-1 fw-black">Premium</h3>
                <p className="display-4 lh-1 fw-black mb-1">
                  <sup className="fs-9 align-super me-1">F.CFA</sup>
                   49.900
                  <span className="fs-5 fw-medium">/mo</span>
                </p>
              </div>
              <Link
                to="https://app.invoice92.com/register?plan=price_1OmcBZJk9RmR1lATKewFpoQV"
                className="btn btn-primary w-100 mt-4"
              >
                Acheter
              </Link>
            </div>
            {/* Col rows */}
            {pricingFour.entries.map(
              ({ cta, text, suffix, tooltip, additional_height }) => (
                <div
                  class={`${
                    additional_height ? `f-h-24` : null
                  } pricing-table-row`}
                >
                  {cta ? (
                    <a href="#" class="btn btn-primary w-100">
                      {text}
                    </a>
                  ) : (
                    <span
                      class="tooltip-trigger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={tooltip}
                    >
                      {text} <span class="d-lg-none d-inline">{suffix}</span>
                    </span>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

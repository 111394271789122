import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  useEffect(() => {
    (() => {
      const megaMenuToggles =
        document.querySelectorAll(".nav-item.dropdown") || [];
      const cssVarBreakPoint =
        getComputedStyle(document.documentElement).getPropertyValue(
          "--theme-breakpoint-lg"
        ) || "992px";
      const breakpointLG = parseInt(cssVarBreakPoint, 10);

      function showMenu(event) {
        const dropdown = event.target.querySelector(".dropdown-menu");
        const menuToggle = event.target.querySelector(".dropdown-toggle");

        if (window.innerWidth < breakpointLG) {
          return;
        }

        if (dropdown && menuToggle) {
          dropdown.classList.add("show");
          menuToggle.setAttribute("aria-expanded", "true");
          menuToggle.classList.add("show");
        }
      }

      function hideMenu(event) {
        const dropdown = event.target.querySelector(".dropdown-menu");
        const menuToggle = event.target.querySelector(".dropdown-toggle");

        if (window.innerWidth < breakpointLG) {
          return;
        }

        if (dropdown && menuToggle) {
          dropdown.classList.remove("show");
          menuToggle.setAttribute("aria-expanded", "false");
          menuToggle.classList.remove("show");
        }
      }

      megaMenuToggles.forEach((toggle) => {
        toggle.addEventListener("mouseenter", (event) => {
          showMenu(event);
        });
        toggle.addEventListener("mouseleave", (event) => {
          hideMenu(event);
        });
      });
    })();
  }, []);

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light border-bottom">
      <div className="container">
        <Link
          className="navbar-brand d-flex align-items-center lh-1 me-10 transition-opacity opacity-75-hover"
          to={`/`}
        >
          <span className="fw-bold text-primary">Invoice92</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between align-items-center"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li className="nav-item position-static dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Fonctionnalités
              </a>
              <div className="dropdown-menu dropdown-megamenu">
                <div className="container">
                  <div className="row py-lg-4 gx-8">
                    <div className="col-auto col-lg-4 mb-4 mb-lg-0 d-flex align-items-start">
                      <div className="position-relative">
                        <h6 className="dropdown-heading">Facturation</h6>
                        <p className="text-muted">
                          Créez des factures, envoyez des rappels de paiement et
                          associez automatiquement les paiements aux factures.
                        </p>
                        <a
                          className="fw-medium fs-7 text-decoration-none link-cover"
                          href="/features/invoicing"
                        >
                          Savoir plus &rarr;
                        </a>
                      </div>
                    </div>
                    <div className="col-auto col-lg-4 mb-4 mb-lg-0 d-flex align-items-start">
                      <div className="position-relative">
                        <h6 className="dropdown-heading">Dépenses</h6>
                        <p className="text-muted">
                          Préparez-vous pour la période des impôts avec toutes
                          vos dépenses organisées en un seul endroit.
                        </p>
                        <a
                          className="fw-medium fs-7 text-decoration-none link-cover"
                          href="/features/expenses"
                        >
                          Savoir plus &rarr;
                        </a>
                      </div>
                    </div>
                    <div className="col-auto col-lg-4 mb-4 mb-lg-0 d-flex align-items-start">
                      <div className="position-relative">
                        <h6 className="dropdown-heading">Inventaire</h6>
                        <p className="text-muted">
                          Gardez le contrôle de vos commandes et de vos
                          quantités tout en gérant votre inventaire en temps
                          réel.
                        </p>
                        <a
                          className="fw-medium fs-7 text-decoration-none link-cover"
                          href="/features/inventory"
                        >
                          Savoir plus &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row py-lg-4 gx-8">
                    <div className="col-auto col-lg-4 mb-4 mb-lg-0 d-flex align-items-start">
                      <div className="position-relative">
                        <h6 className="dropdown-heading">
                          Informations et rapports
                        </h6>
                        <p className="text-muted">
                          Découvrez comment votre entreprise se porte, grâce à
                          une gamme de rapports populaires et au planificateur
                          de flux de trésorerie.
                        </p>
                        <a
                          className="fw-medium fs-7 text-decoration-none link-cover"
                          href="/features/reports"
                        >
                          Savoir plus &rarr;
                        </a>
                      </div>
                    </div>
                    <div className="col-auto col-lg-4 mb-4 mb-lg-0 d-flex align-items-start">
                      <div className="position-relative">
                        <h6 className="dropdown-heading">Application mobile</h6>
                        <p className="text-muted">
                          Gagnez du temps en gérant votre entreprise en
                          déplacement avec votre appareil mobile*.
                        </p>
                        <a
                          className="fw-medium fs-7 text-decoration-none link-cover"
                          href="/features/mobile-app"
                        >
                          Savoir plus &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <Link to={`/pricing`} className="nav-link">
                Tarifs
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/about`} className="nav-link">
                Qui nous sommes ?
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/contact`} className="nav-link">
                Nous contacter
              </Link>
            </li>
          </ul>

          <div className="d-none d-lg-flex">
            <a
              href="https://app.invoice92.com/login"
              className="btn btn-link text-muted"
              role="button"
            >
              Se connecter
            </a>
            <a
              href="https://app.invoice92.com/register"
              className="btn btn-primary ms-2"
              role="button"
            >
              Créer un compte
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

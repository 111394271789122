import React from "react";
import { SVGSHapeNine } from "../svg/svg-shape-nine";
import { Link } from "react-router-dom";

export default function HighlightItem({
  id,
  img,
  title,
  subtitle,
  description,
}) {
  return (
    <section>
      <div class="container py-5 py-lg-8">
        <div class="row g-5 g-lg-10 d-flex align-items-center">
          <div class="col-12 col-md-6 col-xl-5 offset-xl-1 position-relative">
            <picture class="position-relative z-index-20">
              <img
                class="img-fluid rounded-5"
                src={`${process.env.PUBLIC_URL}/assets/images/feature-1.jpeg`}
                alt="config.defaultImgAlt "
              />
            </picture>
          </div>
          <div class="col-12 col-md-6 col-xl-5 offset-xl-1 position-relative">
            <div class="position-relative z-index-20">
              <p class="mb-0 small fw-bolder tracking-wider text-uppercase text-primary">
                {subtitle}
              </p>
              <h4 class="fs-1 fw-bold mb-4 mt-3">{title}</h4>
              <p class="text-muted">{description}</p>
              <Link
                to={`/pricing`}
                class="btn btn-link px-0 me-3 fw-medium text-decoration-none mt-4"
              >
                Consulter les tarifs &rarr;
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import "@popperjs/core";
import "bootstrap";
import Aos from "aos";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.scss";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

(() => {
  const options = {
    duration: 700,
    easing: "ease-out-quad",
    once: true,
    startEvent: "load",
    disable: "mobile",
  };
  Aos.init(options);
})();

import React from "react";

export default function ContactPage() {
  return (
    <section>
      <header className="pt-10">
        <div className="container">
          <div className="text-center col-12 col-sm-9 col-lg-7 col-xl-6 mx-auto position-relative z-index-20">
            <h1 className="display-3 fw-bold mb-3">Contacter nous</h1>
            <p className="text-muted lead mb-0">
              Sélectionnez une catégorie ci-dessous pour envoyer un e-mail à la
              bonne équipe d'assistance, ou Vous pouvez également nous envoyer
              un message général en utilisant le formulaire ci-dessous.
            </p>
          </div>
        </div>
      </header>

      {/* <div className="container position-relative z-index-20 py-7">
        <div className="row g-5">
          <div className="col-12 col-lg-4">
            <div className="card rounded shadow-lg h-100">
              <div className="card-body d-flex align-items-center flex-column justify-content-center text-center p-5">
                <span className="f-w-10 d-block text-primary">
                  <svg
                    className="w-100"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                    <polyline points="2 17 12 22 22 17"></polyline>
                    <polyline opacity=".4" points="2 12 12 17 22 12"></polyline>
                  </svg>
                </span>
                <p className="fw-medium mb-1 my-4 fs-5">Knowledgebase</p>
                <span className="text-muted fs-7 mb-4">
                  Browse our resources or submit a ticket to our support team.
                </span>
                <a className="fw-bolder" href="#">
                  Visit help center &rarr;
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card rounded shadow-lg h-100">
              <div className="card-body d-flex align-items-center flex-column justify-content-center text-center p-5">
                <span className="f-w-10 d-block text-primary">
                  <svg
                    className="w-100"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path opacity=".4" d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path opacity=".4" d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </span>
                <p className="fw-medium mb-1 my-4 fs-5">Contact sales</p>
                <span className="text-muted fs-7 mb-4">
                  Ask about a custom pricing plan, our business products, or
                  request a demo.
                </span>
                <a className="fw-bolder" href="#">
                  Contact sales &rarr;
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card rounded shadow-lg h-100">
              <div className="card-body d-flex align-items-center flex-column justify-content-center text-center p-5">
                <span className="f-w-10 d-block text-primary">
                  <svg
                    className="w-100"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="18" cy="18" r="3"></circle>
                    <circle cx="6" cy="6" r="3"></circle>
                    <path opacity=".4" d="M13 6h3a2 2 0 0 1 2 2v7"></path>
                    <line opacity=".4" x1="6" y1="9" x2="6" y2="21"></line>
                  </svg>
                </span>
                <p className="fw-medium mb-1 my-4 fs-5">Technical Support</p>
                <span className="text-muted fs-7 mb-4">
                  Need help with our API, or assistance using our landing page
                  design tool?
                </span>
                <a className="fw-bolder" href="#">
                  Contact support &rarr;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container">
        <div className="row gx-10 my-10 pt-5">
          <div className="col-12 col-lg-8">
            <p className="mb-3 small fw-bolder tracking-wider text-uppercase text-primary">
              Contacter nous
            </p>
            <h2 className="display-5 fw-bold mb-6">Envoie-nous un message</h2>

            <form action="mailto:fatihoune.ci@gmail.com">
              <div className="row g-5">
                <div className="col-12 col-md-6">
                  <label className="form-label">Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="John Doe"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="john@doe.com"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Société</label>
                  <input
                    type="text"
                    className="form-control"
                    name="company"
                    placeholder="ACME Limited"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Telephone</label>
                  <input
                    type="text"
                    className="form-control"
                    name="telephone"
                    placeholder="+44 (0)208 445 678"
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    name="about"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <div className="col-12 justify-content-end d-flex">
                  <button className="btn btn-primary" type="submit">
                    Envoyer
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="col-12 col-lg-4 mt-5 mt-lg-0">
            <p className="mb-4 small fw-bolder tracking-wider text-uppercase text-primary">
              Retrouvez-nous en ligne
            </p>
            <ul className="list-unstyled mb-5">
              <li className="d-flex align-items-center mb-2">
                <i className="ri-github-line me-3 ri-lg"></i>
                <a className="text-muted" href="#">
                  Youtube
                </a>
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="ri-facebook-line me-3 ri-lg"></i>
                <a className="text-muted" href="#">
                  Facebook
                </a>
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="ri-twitter-line me-3 ri-lg"></i>
                <a className="text-muted" href="#">
                  LinkedIn
                </a>
              </li>
            </ul>
            <p className="mb-4 small fw-bolder tracking-wider text-uppercase text-primary">
              Notre siège social
            </p>
            <p>Maroc, Rabat, Nahda N1 Comp</p>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { SVGSHapeNine } from "../svg/svg-shape-nine";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div class="container pt-4 pt-md-6 pt-lg-8 pb-8 pb-lg-10 position-relative ">
      <div class="row gx-10 py-5 align-items-center">
        <div class="col-12 col-lg-6 position-relative z-index-20 text-center text-lg-start">
          <h1 class="display-3 fw-bold mb-4">
            Logiciel de facturation pour les petites et moyennes entreprises
          </h1>
          <h2 class="fs-5 text-muted fw-light lh-base">
            Suivez les dépenses, personnalisez les factures, générez des
            rapports et bien plus encore d'un seul endroit.
          </h2>
          <div class="mt-4 pt-1 d-flex flex-column flex-md-row justify-content-center justify-content-lg-start">
            <a
              href="http://app.invoice92.com/register"
              class="btn btn-success"
              role="button"
            >
              Essayer gratuitement
            </a>
            <Link
              to={`/features/invoicing`}
              class="btn btn-link text-decoration-none text-muted ms-2 bg-light-hover"
              role="button"
            >
              En savoir plus &rarr;
            </Link>
          </div>
        </div>

        <div class="col-12 col-md-6 col-xl-5 offset-xl-1 position-relative">
          <picture class="position-relative z-index-20">
            <img
              class="img-fluid rounded-5"
              src={`${process.env.PUBLIC_URL}/assets/images/feature-1.jpeg`}
              alt="config.defaultImgAlt "
            />
          </picture>
          {/* <div class="position-absolute bottom-5 end-0 z-index-30 d-none d-lg-block">
            <div class="p-3 bg-white shadow-lg rounded-3 f-w-60 mb-3 d-flex align-items-center me-0 ms-auto">
              <div class="position-relative me-4">
                <picture class="position-relative z-index-0">
                  <img
                    class="f-w-12 rounded-circle"
                    src={`${process.env.PUBLIC_URL}/assets/images/profile-small-2.jpeg`}
                    alt=""
                  />
                </picture>
                <span class="position-absolute z-index-20 d-block f-w-3 f-h-3 border border-3 border-white bg-success rounded-circle bottom-0 end-8"></span>
              </div>
              <div class="lh-sm">
                <small class="align-self-center fs-8">
                  I like it. 👍 Can we try a different shade of orange for the
                  nav?
                </small>
                <span class="fs-9 text-muted fw-medium mt-1 d-block">
                  12 mins ago
                </span>
              </div>
            </div>

            <div class="p-3 bg-white shadow-lg rounded-3 f-w-56 mb-3 d-flex align-items-center me-0 ms-auto">
              <div class="position-relative me-4">
                <picture class="position-relative z-index-0">
                  <img
                    class="f-w-12 rounded-circle"
                    src={`${process.env.PUBLIC_URL}/assets/images/profile-small-3.jpeg`}
                    alt=""
                  />
                </picture>
                <span class="position-absolute z-index-20 d-block f-w-3 f-h-3 border border-3 border-white bg-danger rounded-circle bottom-0 end-8"></span>
              </div>
              <div class="lh-sm">
                <small class="align-self-center fs-8 lh-sm">
                  Orange updated in header and footer.
                </small>
                <span class="fs-9 text-muted fw-medium mt-1 d-block">
                  5 mins ago
                </span>
              </div>
            </div>
          </div> */}

          <div class="d-none d-xl-block f-w-60 position-absolute top-n13 start-n3 opacity-75">
            <span class="d-block">
              <SVGSHapeNine />
            </span>
          </div>
        </div>

        {/* <div class="col-12 col-lg-6 mt-5 mt-lg-0 align-self-stretch position-relative z-index-20">
          <div class="d-flex h-100 bg-dark rounded-3 shadow-lg card">
            <div class="card-header border-white-10 border-1 py-4 d-flex align-items-center">
              <span class="f-w-2 f-h-2 block bg-danger rounded-circle me-2"></span>
              <span class="f-w-2 f-h-2 block bg-warning rounded-circle me-2"></span>
              <span class="f-w-2 f-h-2 block bg-info rounded-circle"></span>
            </div>
            <div class="card-body" style={{ minHeight: "280px" }}>
              <code
                class="highlight fs-8"
                data-typed='{"backSpeed":2, "strings": ["$&nbsp;npm&nbsp;install&nbsp;sigma<br/><span class=\"text-success\">Sigma&nbsp;package&nbsp;installed 👍</span><br/><br/>$&nbsp;npm&nbsp;start&nbsp;design<br/><span class=\"text-success\">Start&nbsp;sharing&nbsp;your&nbsp;designs&nbsp;instantly...</span><br/><br/>$&nbsp;npm&nbsp;launch&nbsp;design<br/><span class=\"text-success\">Push&nbsp;directly&nbsp;to&nbsp;one&nbsp;of&nbsp;our&nbsp;integrated&nbsp;hosts! 🚀</span>"]}'
              ></code>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
